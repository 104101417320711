import React from 'react'

import PropTypes from 'prop-types'

import './card.css'

const Card = (props) => {
  return (
    <div className="card-card card">
      <div className="card-header">
        <img alt="image" src={props.Icon} className="card-icon" />
        <h3 className="card-heading">{props.Title}</h3>
      </div>
      <p className="card-text">{props.Description}</p>
    </div>
  )
}

Card.defaultProps = {
  Icon: '/group%201643.svg',
  Title: 'Quick & Hassle-Free Pick-Up',
  Description:
    "Let us handle the heavy lifting! Our junk removal experts will provide quick and hassle-free pick-up services, making the process of getting rid of your unwanted items as convenient as possible. We'll ensure that your space is left clean and clutter-free, allowing you to focus on what matters most.",
}

Card.propTypes = {
  Icon: PropTypes.string,
  Title: PropTypes.string,
  Description: PropTypes.string,
}

export default Card
